import React from "react";
import { useNavigate} from "react-router-dom";
import { UserAuth } from "../../../context/AuthContext";
import {AiOutlineMail} from "react-icons/ai";
import "./Account.scss";

function Account() {
    const { user, logout } = UserAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate("/login");
            console.log("You are logged out")
        } catch (e) {
            console.log(e.message);
        }
    };

    return (
        <div className="auth container section">
            <div className="secContainer">

                <div data-aos="fade-up" data-aos-duration="2000" className="single">
                <span className="secTitle">
                    Welkom
                </span>
                </div>


                <div className="authContainer">
                    <div data-aos='fade-up' data-aos-duration='2000' className="single">
                        {/*<AiOutlineMail className="icon"/>*/}
                        <p>Je bent ingelogd met: {user && user.email}</p>
                        <button className="btn">Mijn Producten</button>
                        <p>U heeft nog geen producten</p>

                        <button className="btn">Mijn Facturen</button>
                        <p>U heeft nog geen facturen</p>

                        <button className="btn">Mijn Rapportage</button>
                        <p>U heeft nog geen rapportage</p>

                        <button onClick={handleLogout} className="btn"> Logout</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;
