import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import hatice from "../../assets/hatice.png";
import "./Staff.css";

function Staff() {

   useEffect(()=>{
    Aos.init({duration: 2000})
  }, [])

  return (
      <div className="staff container section">

          <div className="secContainer">
              <span className="secTitle">
                Neem contact met ons op
                </span>

              <div className="staffContainer grid">
                  <div data-aos='fade-up' data-aos-duration='2000' className="singleStaff">
                      <div className="imgDiv">
                          <img src={hatice}/>
                      </div>
                      <span className="name">
                        Hatice Altun
                      </span>
                      <span className="jobTitle">
                        Frontend Developer
                      </span>
                      <span className="contact">
                        <a href="tel:+31648800784" className="icon"></a>
                        +31 (0) 6 48 80 07 84
                    </span>
                      <span className="contact">
                        <span><a href="mailto:info@altunit.nl" className="icon"></a></span>
                        info@altunit.nl
                    </span>
                  </div>

                  <div data-aos='fade-up' data-aos-duration='2000' className="singleStaff">
                      <div className="imgDiv">
                          <img src={hatice}/>
                      </div>
                      <span className="name">
                        Hatice Altun
                    </span>
                      <span className="jobTitle">
                        Frontend Developer
                    </span>
                      <span className="contact">
                        +31 (0) 6 48 80 07 84
                    </span>
                      <span className="contact">
                    <span><a href="mailto:info@altunit.nl" className="icon"></a></span>
                        info@altunit.nl
                     </span>
                  </div>

                  <div data-aos='fade-up' data-aos-duration='2000' className="singleStaff">
                      <div className="imgDiv">
                          <img src={hatice}/>
                      </div>
                      <span className="name">
                        Hatice Altun
                    </span>
                      <span className="jobTitle">
                        Frontend Developer
                    </span>
                      <span className="contact">
                      +31 (0) 6 48 80 07 84
                    </span>
                      <span className="contact">
                    <span><a href="mailto:info@altunit.nl" className="icon"></a></span>
                        info@altunit.nl
                     </span>
                  </div>

              </div>
          </div>
      </div>
  );
}

export default Staff;
