import React, {lazy, Suspense, useEffect, useState} from "react";
import {Routes, Route} from "react-router-dom";
import {AuthContextProvider} from "./context/AuthContext";
import {FadeLoader} from "react-spinners";
import Navbar from "./components/Navbar/Navbar";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import Nieuwsbrief from "./components/Nieuwsbrief/Nieuwsbrief";
import Staff from "./components/Staff/Staff";
import Footer from "./components/Footer/Footer";
import Account from "./pages/pagesAuth/Account/Account";
import PasswordReset from "./pages/pagesAuth/PasswordReset/PasswordReset";
import Login from "./pages/pagesAuth/Login/Login";
import SignUp from "./pages/pagesAuth/SignUp/SignUp";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import "./App.css";


// import Home from "./pages/Home/Home";
// import About from "./pages/About/About";
// import WebApplicaties from "./pages/WebApplicaties/WebApplicaties";
// import Demo from "./pages/Demo/Demo";
// import Contact from "./pages/Contact/Contact";

// Onderstaande omgezet naar lazy loading
const Home = lazy(() => import ("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const WebApplicaties = lazy(() => import("./pages/WebApplicaties/WebApplicaties"));
const Contact = lazy(() => import("./pages/Contact/Contact"));


// npm install react-icons
// npm install sass-loader sass webpack --save -dev
// npm install aos --save
// npm install @emailjs/browser
// npm install firebase


function App() {
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true)
        setTimeout(()=>{
            setLoading(false)
        }, 5000)
    },[])


  return (

      <>

          <div className="App">
              <Navbar/>
              <>
                  <div className="spinner mh-50px"/>
                  {
                      loading ?
                          <LoadingPage/>
                          :
                          <Suspense fallback={<FadeLoader color="#5890b4" loading={loading} size={15}/>}>
                              <Routes>
                                  <Route path="/" element={<Home/>}/>
                              </Routes>
                          </Suspense>
                  }
              </>
          </div>
          <AuthContextProvider>
              <Suspense fallback={<FadeLoader color="#5890b4" loading={loading} size={15}/>}>
              <Routes>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/webApplicaties" element={<WebApplicaties/>}/>
                  <Route path="/contact" element={<Contact/>}/>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/PasswordReset" element={<PasswordReset/>}/>
                  <Route path="/signup" element={<SignUp/>}/>
                  <Route path="/account" element={<ProtectedRoute>
                      // in de account page plaats je data van cursus. Voor nu test met webapplicaties
                      <Account/>

                  </ProtectedRoute>}
                  />
              </Routes>
              </Suspense>

          </AuthContextProvider>

          <Nieuwsbrief/>
          <Footer/>


      </>

  );
}

export default App;
