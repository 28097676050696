// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDou9TeGU4ZJaqWvUHNsAlCjVA0ZOmGwFw",
    authDomain: "altunit-8500b.firebaseapp.com",
    projectId: "altunit-8500b",
    storageBucket: "altunit-8500b.appspot.com",
    messagingSenderId: "445894919455",
    appId: "1:445894919455:web:3bbc839c367f6ab0a49c2a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app
