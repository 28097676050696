import React, {useEffect, useState} from "react";
import {auth, app} from "../../firebase";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {Link} from "react-router-dom";
import Logo from "../../assets/Logo.png";
import {AiOutlineClose, AiOutlineLogin, AiOutlineLogout} from "react-icons/ai";
import {AiOutlineMenu} from "react-icons/ai";
import "./Navbar.scss";


function Navbar() {
    const [active, setActive]  = useState("menuDiv")
    const showNavBar = () =>{
        setActive("menuDiv activeNavbar")
    }

    const removeNavBar = () =>{
        setActive("menuDiv")
    }


    const [authenticatedUser, setAuthenticatedUser] = useState('');
    useEffect(() => {
        const listenAuth = onAuthStateChanged(auth, (user) =>{
            if (user){
                setAuthenticatedUser(user)
            } else {
                setAuthenticatedUser(null)
            }
        })
        return () => {
            listenAuth();
        }
    },[])

    const userSignOut = () => {
        signOut(auth).then(() =>{
            alert("U bent uitgelogd!")
            console.log("Uitgelogd!")
        }).catch(error => console.log("error"))
    }

    return (
        <div>
            <div className="header flex">

                <Link to="/">
                    <span className="logo-container">
                        <img src={Logo} alt="logo"/>
                    </span>
                </Link>

                <div className={active}>
                    <ul className="menuLists">
                        <li className="navItem">
                            <Link to="/" onClick={removeNavBar} className="menuLink">
                                Home
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="/about" onClick={removeNavBar} className="menuLink">
                                Over Mij
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="/webApplicaties" onClick={removeNavBar} className="menuLink">
                                Web Applicaties
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="/contact" onClick={removeNavBar} className="menuLink">
                                Contact
                            </Link>
                        </li>
                        <>
                            { authenticatedUser === null ?
                                <>
                                    <ul>
                                        <li className="navItem">
                                            <Link to="/login" onClick={removeNavBar} className="menuLink">
                                                <AiOutlineLogin className="icon" size={15}/> Inloggen
                                            </Link>
                                        </li>
                                    </ul>
                                </> :
                                <>
                                    <ul>
                                        <li className="navItem">
                                            <Link to="/" className="menuLink"
                                                  onClick={() => {
                                                      {userSignOut(removeNavBar())}
                                                  }}
                                            >

                                                <AiOutlineLogout className="icon" size={15}/> Uitloggen
                                            </Link>
                                        </li>
                                    </ul>
                                </>
                            }
                        </>
                    </ul>

                    <div onClick={removeNavBar} className="closeNavbar">
                        <AiOutlineClose className='icon'  />
                    </div>
                </div>
                <div onClick={showNavBar} className="toggleNavbar">
                    <AiOutlineMenu className="icon" size={22}/>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
