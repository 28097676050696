import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import ScrollTopTop from "./components/SrollToTop/SrollToTop";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <ScrollTopTop/>
            <App/>
        </Router>
    </React.StrictMode>
);

reportWebVitals();
