import React, {useEffect} from "react";
import {HiPhone} from "react-icons/hi";
import {MdEmail} from "react-icons/md";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Footer.css";
import {Link} from "react-router-dom";


function Footer() {

  useEffect(() => {
    Aos.init({duration: 2000})
  }, [])

  return (
      <div className="footer">
        <div className="secContainer container">
          <div className="content grid">

            <div data-aos='fade-up' data-aos-duration='2000' className="row">
              <div className="spanText">
                CONTACT
              </div>

              <div className="contactDiv">
              <span className="flex">
                <a href="tel:+31648800784" className="icon"><HiPhone/> +31 (0) 6 48 80 07 84 </a>
              </span>

                <span className="flex">
                <a href="mailto:info@altunit.nl" className="icon"><MdEmail/> info@altunit.nl </a>
              </span>

                {/*<span className="flex">*/}
                {/* <a target="_blank" href={"https://github.com/hatice-altun"}><AiFillGithub/> github.com/hatice-altun</a>*/}
                {/*</span>*/}

              </div>
            </div>

            <div data-aos='fade-up' data-aos-duration='2000' className="row">
              <div className="spanText">
                AltunIT.nl
              </div>

              <div className="singleNews">
              <span className="text">
              Your Personal Guide for a Better Web
              </span>
                <p>
                  {/*// nog niks ingevuld*/}
                </p>
              </div>
            </div>

          </div>
          <div className="bottomDiv flex">
            <p>Copyright 2023 AltunIT - All rights reserved</p>
            {/*<p>Privacy Policy</p>*/}
            {/*<p>Disclaimer</p>*/}
            {/*//check de united consumers */}
          </div>
        </div>
      </div>
  );
}

export default Footer;
