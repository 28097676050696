import React, {useEffect, useRef} from "react";
import emailjs from "@emailjs/browser";
import video1 from "../../assets/AltunIT-video1.mov";
import videoAltunIT from "../../assets/AltunIT-videoSmall.mp4";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Nieuwsbrief.scss";

// Om je Discount/Newsletter form te koppelen aan je Template van EmailJS voer het onderstaande in:
//npm install @emailjs/browser
function Nieuwsbrief() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_vpzbkm6",
                "template_6pe6e7c",
                form.current,
                "Hfe0Rzn65XV9vpaRU"
            )
            .then((result) => {
                console.log(result.text);
                console.log("Message send")
            }, (error) => {
                console.log(error.text);
            })
            .then(message => alert("Bedankt! U bent ingeschreven voor onze Nieuwsbrief!"))
    };

    useEffect(()=>{
        Aos.init({duration: 2000})
    }, [])

    return (
        <div className="nieuwsbrief">
            <form ref={form} onSubmit={sendEmail}>
                <div className="nieuwsbrief section">
                    <div className="secContainer">
                        <video src={videoAltunIT} autoPlay playsInline={true} loop muted typeof='mov'></video>
                        <div className="textDiv">
                    <span data-aos='fade-up' data-aos-duration='2000' className="title">
                        Schrijf je in voor onze Nieuwsbrief
                    </span>
                            <p data-aos='fade-up' data-aos-duration='2500'>
                                Ontvang de nieuwste aanbiedingen
                            </p>

                            <div data-aos='fade-up' data-aos-duration='3000' className="input_btn flex">
                                <input
                                    type="email"
                                    name="user-email"
                                    placeholder="Vul hier uw e-mailadres in..."
                                    required
                                />
                                <button type="submit" className="btn">AANMELDEN</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Nieuwsbrief;
