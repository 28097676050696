import React, {useEffect, useState} from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {FadeLoader} from "react-spinners";
import { useTypewriter } from "react-simple-typewriter";
import Logo from "../../assets/Logo.png";
import "./LoadingPage.scss";



function LoadingPage() {
    const [reloading, setReloading] = useState(false);
    useEffect(()=>{
        setReloading(true)
        setTimeout(()=>{
            setReloading(false)
        }, 5000)
    },[])

    useEffect(() => {
        Aos.init({duration: 2000})
    }, [])

    useEffect(()=>{
        Aos.init({duration: 4000})
    }, [])

    const [text] = useTypewriter({
        words: ["Your Personal Guide for a Better Web", "Uw Persoonlijke Gids Voor Uw Web"],
        loop: false,
        typeSpeed: 20,
        deleteSpeed: 10,
        delaySpeed: 2000,
    });

    return (
        <div className="loadingPage">
            <FadeLoader color="#5890b4" loading={reloading} size={20}/>

            <div data-aos='fade-up' data-aos-duration='2000'>
                <img className="logo" src={Logo} alt=" "/>
            </div>

            <div className="slogan-container">
                <p>{text}|</p>
            </div>

        </div>
    );
}

export default LoadingPage;
