import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import {AiOutlineCheck, AiOutlineMail} from "react-icons/ai";
import {UserAuth} from "../../../context/AuthContext";
import "./PasswordReset.scss";

function PasswordReset() {
    const [message, setMessage] = useState('');
    const emailRef = useRef();
    const { forgotPassword } = UserAuth();

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const forgotPasswordHandler = () => {
        const email = emailRef.current.value;
        if (email)
            forgotPassword(email).then(() => {
                emailRef.current.value = "";
                setMessage(<AiOutlineCheck className="icons" size={22}/>)
                alert("We hebben een reset e-mail gestuurd naar uw e-mailadres!")
            }).catch((error) => {
                setMessage("E-mailadres onbekend!")
            });
        };


    return (
        <div className="auth container section">
            <div className="secContainer">
                <div data-aos="fade-up" data-aos-duration="2000" className="single">
                <span className="secTitle">
                    Wachtwoord Wijzigen
                </span>
                </div>

                <div className="authContainer">
                    <div data-aos='fade-up' data-aos-duration='2000' className="single">
                        <form onSubmit={onSubmit}>
                            <div className="auth">
                                <div className="fields">
                                    <div className="e-mailadres">
                                        <label>
                                            <AiOutlineMail className="icons"/>
                                            <input
                                                type="email"
                                                placeholder="E-mailadres"
                                                ref={emailRef}
                                                required
                                            />
                                        </label>
                                    </div>
                                    <h6>{message}</h6>
                                    <button className="btn" onClick={forgotPasswordHandler}>Wijzig wachtwoord</button>
                                </div>

                                <p>Heb je nog geen account? Klik
                                    <Link to="/signup"> hier! </Link>
                                </p>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
