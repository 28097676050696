import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {UserAuth} from "../../../context/AuthContext";
import {AiOutlineCheck, AiOutlineMail} from "react-icons/ai";
import {VscLock} from "react-icons/vsc";

function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { createUser} = UserAuth();
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setPassword(e.target.value)


          const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,}$/;
            if (password === "") {
                setMessage("Vul hier uw wachtwoord in!")
            } else if (password.length >6) {
                setMessage(<AiOutlineCheck className="icons" size={22}/>)
            } else if (!regExp.test(password)) {
                setMessage("Wachtwoord moet minimaal 6 karakters bevatten!")
            }

        try {
            await createUser(email, password);
            alert("Welkom! Uw account is aangemaakt!")
            navigate("/account")
        } catch (e) {
            setError(e.message);
            console.log(e.message);
        }
    };



return (
        <div className="auth">
            <div className="signup container section">
                <div className="secContainer">
                    <div data-aos="fade-up" data-aos-duration="2000" className="single">
                <span className="secTitle">
                    Account maken
                </span>
                    </div>

                    <div className="authContainer">
                        <div data-aos='fade-up' data-aos-duration='2000' className="single">
                            <form onSubmit={handleSubmit}>
                                <div className="auth">
                                    <div className="fields">
                                        <div className="e-mailadres">
                                            <label>
                                                <AiOutlineMail className="icons" size={22}/>
                                                <input
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type="email"
                                                    placeholder="E-mailadres"
                                                    required
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <div className="password">
                                                <label>
                                                    <VscLock className="icons" size={22}/>
                                                    <input
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        type="password"
                                                        placeholder="Wachtwoord"
                                                    />
                                                </label>
                                            </div>
                                            <h6>{message}</h6>
                                        </div>
                                        <button className="btn">Maak account aan</button>
                                    </div>
                                    <p>Heb je al een account? Klik
                                        <Link to="/login"> hier! </Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
