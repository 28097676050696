import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Aos from "aos";
import {UserAuth} from "../../../context/AuthContext";
import {AiOutlineMail} from "react-icons/ai";
import {VscLock} from "react-icons/vsc";
import "./Login.scss";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = UserAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('')

        if (password === "") {
            setMessage("Vul hier uw wachtwoord in!")
        } else {
            setMessage(" ")
        }

        try {
            await login(email, password)
            navigate("/account")
        } catch (e) {
            alert("Combinatie e-mailadres en wachtwoord is niet correct! / E-mailadres onbekend!")
            setError(e.message)
            console.log(e.message)
        }
    };

    useEffect(()=>{
        Aos.init({duration: 2000})
    }, [])


    return (

        <div className="auth container section">
            <div className="secContainer">

                <div data-aos="fade-up" data-aos-duration="2000" className="single">
                <span className="secTitle">
                    Inloggen
                </span>
                </div>

                <div className="authContainer">
                    <div data-aos='fade-up' data-aos-duration='2000' className="single">
                        <form onSubmit={handleSubmit}>
                            <div className="auth">
                                <div className="fields">
                                    <div className="e-mailadres">
                                        <label>
                                            <AiOutlineMail className="icons" size={22} />
                                            <input
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                placeholder="E-mailadres"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div className="password">
                                        <label>
                                            <VscLock className="icons" size={22}/>
                                            <input
                                                onChange={(e) => setPassword(e.target.value)}
                                                type="password"
                                                placeholder="Wachtwoord"
                                            />
                                        </label>
                                    </div>
                                    <h6>{message}</h6>
                                    <button className="btn">Login</button>
                                </div>

                                <p>Heb je nog geen account? Klik
                                    <Link to="/signup"> hier! </Link>
                                </p>
                                <p>
                                    <Link to="/PasswordReset"> Wachtwoord vergeten? </Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
